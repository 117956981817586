// Resets margins and paddings for headers, ul, ol and p
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders
// and radius of all form items.
// ============================
input,
textarea,
button {
  border: 0;
  border-radius: 0;
  outline: none;
  appearance: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

// Use border-box for everything
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  width: 100%;
  max-width: 100%;
}


html,body { 
    height: 100%;
    // overflow-x: hidden;
    
}
img {
    max-width: 100%;
    height: auto;
    // margin: 0;
    display: block;
}
figure {
  margin: 0;
}