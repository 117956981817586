.c-services {



}

.c-services__list {
    // border: 1px green solid;
    h2 {
        padding: 0.5em;
    }
    li {
        background: url(../images/check.svg);
        background-repeat: no-repeat;
        background-size: 1em; // margin-left: 10em;
        background-position: 1.5em 0.25em;
        padding-left: 3.5em;
        margin-bottom: 0.25em;
        
    }
}

.c-services__image {
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////

@include media('<=phone') {
    .c-services__image {
    // overflow: hidden;
    img {
      padding: 0 1em;
    }
}

}
