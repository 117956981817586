// PROJECT GRID
.c-projects {
    // margin-bottom: 3em;
    // padding: 0 .5em;
}

.c-project {
    border: 1px #e4e4e4 solid;
    border-radius: 3px;
    box-shadow: 0 0 25px #dadada;
    background-color: $white;
    &:last-child {
        // margin-bottom: 3em;
    }
}



.c-project__image {
    img {
        border-radius: 3px 3px 0 0;
    }
}

.c-project__content {
    padding: 0.5em 1em 1em;
    display: grid;
    grid-template-columns: 20% 78%;
    grid-column-gap: 2%;


    span {
        @extend h5;
        font-weight: 700;
    }

    li {
        line-height: 1.2;
        font-size: 95%;
    }
}



.c-project__content--title {
    text-align: right; // margin-right: 0.75em;
}

/////////////////////////////////////////////////
////// FEATURED PROJECT /////////////////////////
/////////////////////////////////////////////////
.c-featured-project {
    // padding: 3em 0;
    grid-template-columns: 49% 49%; // grid-template-rows: auto 30em;
}

.c-featured-project__content {
    padding: 1em 0;
}

.c-featured-project__media {
    width: 100%;
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - DESKTOP ///////////////
/////////////////////////////////////////////////
@include media('<=desktop') {

    .c-featured-project {}

    .c-project__content {
        // grid-template-columns: 20% 74%;
        grid-column-gap: 8%;
        margin-right: 1em;
    }

}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET ///////////////
/////////////////////////////////////////////////
@include media('<=tablet') {

    .c-featured-project {
        // padding: 3em 0;
        grid-template-columns: auto;
        grid-template-rows: auto 30em;
    }

    .c-project__content {
        grid-template-columns: 29% 69%;
        grid-column-gap: 2%;
        font-size: 99%;
    }

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=phone') {

    .c-projects {
        // margin-bottom: 3em;
        padding: 0 .5em;
    }


    .c-project:last-child {
        // margin-bottom: 6em;
    }
    .c-project__content {
        // padding: 0.5em 1em 1em;
        // display: grid;
        grid-template-columns: 30% 70%;


        span {
            // @extend h5;
            // font-weight: 700;
            // font-size: 95%;
        }

        li {
            // line-height: 1.2;
            // font-size: 85%;
        }
    }

    .c-featured-project {
        // padding: 3em 0;
        // grid-template-columns: auto;
        grid-template-rows: auto 15em;
    }


    .c-featured-project__content {
        // font-size: 90%;
        padding: 0 1em;
    }
    .c-featured-project__media {
        padding: 0 1em;
    }

}
