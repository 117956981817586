.c-banner {
    position: relative;
    background: //top, transparent red, faked with gradient */
    linear-gradient( rgba(23, 23, 23, 0.8),
    rgba(23, 23, 23, 0.8)), //* bottom, image */
    url('/user/themes/atlantic/images/hire-bg.jpg');

    &__left-background {
        // background-color: green;
        background-color: $primary;
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
    }

    &__container--outer {
        @extend .l-wrapper;

    }

    &__container--inner {
        display: grid;
        grid-template-columns: auto 35%;

        height: 130px;

    }



    &__text {
        display: flex;
        align-items: center;
        position: relative;
        background-color: $primary;
        h3 {
            font-size: 24px;
            margin: 0;
            color: $white;
        }
        svg {
            height: 90px;
            width: 90px;
            fill: #fff;
        }


    }

    &__button {
        height: 100%; // @include border(red);
        // justify-content: flex-end;
        display: flex;
        align-items: center;
        justify-content: center; // background-color: grey;
    }


    &__arrow {
        border-bottom: 64px solid transparent;
        border-color: transparent transparent transparent $primary;
        border-left: 40px solid $primary;
        border-style: solid;
        border-top: 64px solid transparent;
        content: "";
        display: block;
        left: 100%;
        position: absolute;
        top: 0;

    }

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET //////////////
/////////////////////////////////////////////////

@include media('<=tablet') {
    .c-banner__button {
        // background-color: pink; // @include grid1;
    }
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////


@include media('<=phone') {
    // @include hidden;
    .c-banner__container--inner {
        grid-template-columns: auto;
        height: auto;
    }



.c-banner__text {
    padding: 1em 0;
    h3 {
        // font-size: 95%;
        font-weight: 500;
    }
}


    .o-button {
        margin: 1.5em auto;
    }



}
