

$white: #fff;
$offwhite: #f0f0f0;
$black: #212121;
$primary: #b81319; // Red



$header--contact-background: #212121;
$header--contact-color: #eee;
$header-background: #fff;
$header-color: #000;

$section-background: #f9f9f9;
$section-color: $black;



$footer-background: #252525;
$footer-color: #999;
$footnote-background: #000;
$footnote-color: #fff;


