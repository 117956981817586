.c-header {
    background-color: $white;
}

.c-header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include colors($header-background, $header-color);
    padding: 0.8em 0;
    height: 92px;
}

.c-header__logo {
    padding: 0;
    width: 15em;
}


.c-header__menu {

    .c-menu__items {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .c-menu__item {
        padding-left: $padding*2;
        a {
            padding: 1em; // margin-left: 1em;
            font-size: 1.1em;
            font-weight: 500;
            text-transform: uppercase;
            &:last-child {
                margin-right: -1em;
            }
        }
    } 
    
    .c-menu__item--is-active {
        a {
            color: $primary;
        }
    }


}

// MOBILE HEADER
.c-header--mobile {
    // 
    object {
        // ALLOW OBJECT TO BE CLICKABLE FOR SLIDEOUT MENU
        pointer-events: none;
    }
}

.c-header--mobile-logo {
    background-color: $white;
    // margin-top: 2.9em;
    padding: 2em;
}

.c-contact-banner__hamburger {
    div {
  width: 35px;
  height: 3px;
  background-color: $offwhite;
  margin: 6px 0;
}
// margin: -0.25em 0 0 4em;
margin: -.25em 3.5em 0 0;
}


///////////////////////
// MOBILE SLIDEOUT MENU
///////////////////////
#menu {
    padding: 1.5em 1em;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 500;
    
    a {
        color: $black;
    }

    li {
        margin: 0.5em 0;
        padding: 0.25em 0;
        border-bottom: 1px solid $offwhite;
    }

    .c-menu__item--is-active {
        a {
            color: $primary;
        }
    }
}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET /////////////
/////////////////////////////////////////////////
@include media('<=desktop') {


    .c-header__container {
        padding: 0 1em;
    }


    .c-header__menu {
        a {
            font-size: .8em;
        }
        .c-menu__item {
            // padding-right: 1em;
        }
        .c-menu__item {
            padding: .1em;
        }
    }

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE /////////////
/////////////////////////////////////////////////
@include media('<=phone') {
.c-contact-banner {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 111;
    // height: 2.9em;
    // margin-bottom: 100px;
}






}