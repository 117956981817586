/////////////////////////////////////////////////////////
// SECTION LAYOUTS
/////////////////////////////////////////////////////////
section {
	// overflow: -webkit-paged-x;
}


.l-section {
	// margin: 3em auto;
	position: relative;
	padding: 0;
	margin: 0;
	// overflow: -webkit-paged-x;
}

.l-section--alt {
	// @extend .l-section;
	background-color: $offwhite;
	// overflow: -webkit-paged-x;
}

.l-section__hero {
	padding: 0;

}

.l-section__container {
	// @include container;
}

.l-section__title {
	text-align: center; // margin-bottom: 1em;
	h3 {
		color: #444444;
		font-size: 25px;
		font-weight: 600;
		margin-bottom: .5em;
		text-transform: capitalize;
	}

	span {
		color: $primary;
	}
}

.l-section__title-underline {
	background: $primary none repeat scroll 0 0;
	height: 3px;
	margin: 0 auto 3.5em;

	width: 100px;
}



/////////////////////////////////////////////////////////
// CONTAINER LAYOUTS
/////////////////////////////////////////////////////////
.l-wrapper {
	width: $body--width;
	max-width: $body--max-width;
	min-width: $body--min-width;
	margin: 0 auto;

}

.l-container {
	padding: 3em 0; // margin-bottom: 3em;
	overflow: -webkit-paged-x;
}

.l-container--2-columns {
	display: grid;
	grid-template-columns: 49% 49%;
	grid-column-gap: 2%;
	grid-row-gap: 2%;
	justify-items: center;
	// padding: 1em 0;





}

.l-container--3-columns {
	display: grid;
	grid-template-columns: 32% 32% 32%;
	grid-column-gap: 2%;
	grid-row-gap: 2%; // padding: 1em 0;
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - DESKTOP /////////////
/////////////////////////////////////////////////
@include media('<=desktop') {

	.l-wrapper {
		// padding: 1.5em 0;
	}

	.l-container {
		padding: 3em 1em; // margin: 1.5em auto;
	}

}

.l-container--3-columns {
	
	// grid-column-gap: 2%; // padding: 2%;
	// grid-auto-rows: auto;
}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET ///////////////
/////////////////////////////////////////////////
@include media('<=tablet') {




	.l-container--2-columns {
		// grid-template-columns: auto; // padding: 1em;
		// grid-column-gap: 0;
		// width: 100%;

	}

	.l-container--3-columns {
		grid-template-columns: 49% 49%;
		// grid-template-columns: auto; // padding: 1em;
	}


}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=phone') {

	.l-container {
		padding: 3em 0; // margin: 1.5em auto;
	}

	.l-section__title-underline {

		margin: 0 auto 2em;


	}

	.l-container--2-columns {
		grid-template-columns: auto; // 
		// padding: 0 1em;
		// grid-column-gap: 0;

	}

	.l-container--3-columns {
		grid-template-columns: auto; // padding: 1em;
		grid-row-gap: 1%;
	}


}
