    .c-contact {
        padding: 2em 1em;
        
    }

.c-contact-form {
    display: grid;
    grid-template-columns: 30% auto;
    grid-column-gap: 1em;
    padding: 1em 0;
    // background-color: $offwhite;


}


.c-contact-form__widgets {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 1em;

}

.c-contact-form__widget {
    // border: 1px solid pink;
    border: 1px #e4e4e4 solid;
    border-radius: 3px;
    box-shadow: 0 0 25px #dadada; // margin: 0.5em;
    padding: 1em;
    background-color: #fafafa;
}

.c-contact-form__widget--phone {
    @extend .c-contact-form__widget;

}

.c-contact-form__widget--email {
    @extend .c-contact-form__widget;
}

.c-contact-form__widget--address {
    @extend .c-contact-form__widget;
}

.c-contact-form__widget--registrations {
    @extend .c-contact-form__widget;
}

.c-contact-form__container {
    border: 1px solid pink;
    border: 1px #e4e4e4 solid;
    border-radius: 3px;
    box-shadow: 0 0 25px #dadada; 
    // display: inline-block;
    // width: 100%;
    padding: 1.25em 0.75em;
    background-color: #fafafa;


    // .form__name {
    //     border: 5px solid pink;
    // }



}


input,
textarea {
    background-color: #fff;
    border-color: #e0e0e0;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
    border-width: 1px;
    border-style: solid;
    color: #474e57;
    padding: 1em;
    width: 100%;


}

.form__field {
    float: left;
    width: 50%;
    padding-bottom: .5em;
    
    

    &--button {
        // padding: 2em;
    }
}

.textarea {
    width: 100%;
    // border: 1px solid red;
}

.form-label {
    // @extend h3;
    display: none;

}

.form-input-wrapper, .form-textarea-wrapper, .rc {
    // border: 1px solid green;
    padding: 0 .5em;

}


.form-data {
    // border: 1px solid green;
    margin-bottom: 1em;
}

textarea {
    min-height: 13em;
}
.mytextarea {
    // width: 100%;
    // min-height: 4em;
}

.input {
    // padding: 1em;
    // width: 100%;
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET ///////////////
/////////////////////////////////////////////////

@include media('<=tablet') {
.c-contact-form {

    grid-template-columns: 36% auto;
    grid-column-gap: 2%;
    // padding: 1em 0;
    // background-color: $offwhite;


}


.rc {
    width: 100%;
}

button {
	     width: 100%;
	     padding: 1em 0;
	     font-size: 150%;
	     margin: 0 0.5em;
	 }
}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=phone') {
    
    .c-contact {
        padding: 1em;
        
    }
	
.c-contact-form {
    grid-template-columns: 100%;
    grid-row-gap: 1em;
    // grid-column-gap: 0;
    
    // padding: 1em;
    justify-items: center;

    // margin: auto 1em;
    
}

.c-contact-form__widgets {

    // grid-row-gap: 1em;
    width: 100%;
    // font-size: 85%;
    // padding: 0 1em;

}
	.c-contact-form__container {
	    width: 100%;
	}
	
	.form__field {
	    width: 100%;
	}
	
	
	 button {
	     width: 100%;
	     padding: 1em 0;
	     font-size: 150%;
	   //  margin: 0 1em;
	 }
	
}


