@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Rokkitt:400,500,600');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700');



body {
    font-size: 16px;
    line-height: 26px;
    color: #222;
    font-family: 'Raleway', sans-serif;
    font-weight: 400
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    
}

h1 {
    margin-bottom: 0.25em;
}

h2 {
    margin-bottom: 0.25em;
}

h3 {
    margin-bottom: 0.5em;
}

p {
    margin-bottom: 1em;
}

a{
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: $primary;
    text-decoration: none;
}
a:focus {
    text-decoration: none;
    outline: medium none;
    color: $primary
}
a:hover {
    color: $primary;
    text-decoration: none;
    // color: inherit;
}
ul, ol {
    margin: 0;
    padding: 0;
}
li {
    list-style-type: none;
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET ///////////////
/////////////////////////////////////////////////

@include media('<=tablet') {
    body {
        font-size: 15px;
    }
}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////

@include media('<=phone') {
    body {
        font-size: 14px;
    }
}
