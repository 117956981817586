@mixin add-border( $border-position: all,
$border-size: 1px,
$border-pattern: solid,
$border-color: $black) {

    @if $border-position==$border-position-all {
        border: $border-size $border-pattern $border-color;
    }
    @else {
        border-#{$border-position}: $border-size $border-pattern $border-color;
    }
}

/////////////////////////////////////////////////
// BACKGROUND IMAGE MIXIN ///////////////////////
// HAS DEFAULT IMAGE IF NO IMAGE SPECIFIED //////
// @include background-image(); /////////////////
// @include background-image('image.jpg'); //////
/////////////////////////////////////////////////
@mixin background-image($url: '/user/themes/atlantic/images/star-mountain.jpg') {
    background: //top, transparent red, faked with gradient */
    linear-gradient( rgba(23, 23, 23, 0.3),
    rgba(23, 23, 23, 0.2)), //* bottom, image */
    url($url);
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat; 
    // top: 0;
}

.c-hero {
    @include colors(black, white) // background: grey;
    @include background-image;
    .fa-home {
        color: $primary;
    }

}


.c-hero__image {

    position: relative;
    width: 100%;
    height: 560px; // background-image: url(/user/themes/atlantic/images/hero.jpg);
    // background-size: cover;
    // background-position: bottom;
}

.c-hero__image--small {
    // width: 100%;
    @include background-image('/user/themes/atlantic/images/header.jpg');
    height: 220px; 
    background-position: center center;
    // margin-bottom: 1em;
    // .overlay {
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(255, 255, 255, 0.25);
    // }
}



.c-hero__content--wrapper {
    position: relative; // display: grid;
    // grid-template-columns: auto auto auto;
    @extend .l-container;
    height: 560px; // width: auto;
    // top: 50%;
    // left: 40%;
    h2 {
        font-size: 3em;
        font-weight: 300; // font-family: Raleway;
        margin-bottom: 0.5em;
        text-shadow: 0 0 2em $black;
        line-height: 1.1;
        span {
            background-color: $primary;

        }

    }
    p {
        font-size: 1.5em;
    } // transform: translate(-40%, -50%);
    a {
        margin: 0.5em;
    }
}

.c-hero__content--inner-container {
    position: absolute;
    bottom: 0;
    left: 8em; // margin-left: 4em;
    bottom: 5em; // left: 2em;
    color: green;
    h2 {
        color: $white;
    }
}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=phone') {

    .c-hero__content--wrapper,
    .c-hero__image {
        height: 400px;
    }
    .c-hero__content--inner-container {

        left: 0; // margin-left: 4em;
        bottom: 0; // left: 2em;
        padding: 1.25em;
        h2 {
            font-size: 2.25em;
        }
    }
    
    .c-hero__content--button-container {
        display: grid;
        grid-template-columns: 50% 50%;
        text-align: center;
        
        .o-button {
            padding: 1em;
        }
    }
    

}
