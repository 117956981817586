// Buttons
//
// Description: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et doloribus aut placeat laborum beatae minus obcaecati, rerum, deleniti sit ut cupiditate quae quasi veritatis eveniet tempora eos voluptas aspernatur eius.
//
// Markup: 
// <button class="button {{modifier_class}}">Button Element</button>
// <input class="button {{modifier_class}}" type="button" value="Input Button" />
//
// :hover - When user hovers over button.
// :focus - When button is focused.
// .button--small - A small button.
// .button--large - A large button.
//
// Styleguide Components.button 



// .o-button {
//   padding: 1em 2em;
//   margin: .5em;
//   display: inline-block;
//   font-size: .9em;
//   font-family: Helvetica, sans-serif;
//   font-weight: bold;
//   text-transform: uppercase;
//   color: #f56476;
//   background-color: #fff;
//   border: 2px solid $primary;
//   transition: .2s color, .2s background-color, .2s border-color;
// }
// .o-button:hover {
//   color: #fff;
//   background-color: #f56476;
// }
// .o-button:focus {
//   color: #3ddc97;
//   border-color: currentColor;
// }
// .o-button--small {
//   font-size: .5em;
// }
// .o-button--large {
//   font-size: 1.5em;
// }
.o-button:before {
  background: $primary none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 50% 0 0;
  transition-duration: 0.5s;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
  width: 100%;
  z-index: -1;
}

.o-button:hover:before {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);

}

.o-button {
  // backface-visibility: hidden;
  // background-color: transparent;
  background-color: rgba(0,0,0,0.2);
  border: 3px solid $primary;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  color: #fff !important;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 600; // margin-top: 40px;
  padding: $padding $padding*2;
  position: relative;
  text-transform: uppercase;
  transform: translateZ(0px);
  transition-duration: 0.75s;
  transition-property: color;
  vertical-align: middle;
  cursor: pointer;
  



}

.o-button--alt {
  @extend .o-button;
  background-color: $primary;
  padding: 1em 2.5em;
}