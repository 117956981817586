///////////////////////////////////////
// CONTACT BANNER ACCROSS TOP /////////
///////////////////////////////////////
.c-contact-banner {
	@include colors($header--contact-background,
	$header--contact-color);
	p {
		margin: 0;
		margin-left: 0.25em;
		font-size: 0.9em;
	}
	&__container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		fill: $primary; //<-- SVG COLOR 
	}

	&__items {
		// @include flex;
		display: flex; // text-align: left;
		// justify-content: flex-start;
		padding: $padding/2 0 $padding/2 $padding*2;
	}

	&__item {
		padding-left: $padding/4;
	}
}





.c-header--mobile {}

.js-header__logo {
	position: relative;
	height: 300px;

	object {
		z-index: -1;
		padding: 1em;
	}
}






/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - DESKTOP /////////////
/////////////////////////////////////////////////
@include media('<=desktop') {

	.c-contact-banner__container {
		padding: 0 1em;
	}
}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - TABLET /////////////
/////////////////////////////////////////////////
@include media('<=tablet') {
	

}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE /////////////
/////////////////////////////////////////////////
@include media('<=phone') {
	.c-contact-banner__container {
		flex-direction: column;
		// align-items: left;
		font-size: 1em;
		// padding: 1em;
		// padding: 0.25em 1em;
		p {
			
		}
		
	}
	.c-contact-banner__items {
		padding: 1em 0;
		width: 100%;
	}
	
	
	.c-contact-banner__hamburger {
		
	}
	
	
	
	
	
}