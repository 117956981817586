.c-key-people {
    // @include grid3;
    // grid-template-columns: 32% 32% 32%;
    // justify-content: space-between;
    
}

.c-key-people__person {
    border: 1px #e4e4e4 solid;
    border-radius: 3px;
    box-shadow: 0 0 25px #dadada;
}

.c-key-people__person--image {
    img {
        border-radius: 3px 3px 0 0;
    }
}

.c-key-people__person--content {
    padding: 0.5em 1em;

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - DESKTOP ///////////////
/////////////////////////////////////////////////
// @include media('>=tablet') {
//     .l-container--3-columns {
//         grid-template-columns: 32% 32% 32%;
//     }

// }
/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=phone') {
    .c-key-people {
        padding: 0 1em;
    }

}
