/////////////////////////////////////////////////
// FOOTER ///////////////////////////////////////
/////////////////////////////////////////////////
.c-footer {
	@include colors($footer-background, $footer-color);
}

.c-footer__container {
	display: grid;
	grid-template-columns: 35% 35% 30%;
	justify-items: center;
	padding: 50px 0;
}

.c-footer__content {
	h4 {
		color: #fff;
	}
}

.c-footer__content--logo {
	object {
		height: 12em;
	}
}

.c-footer__content--links,
.c-footer__content--contact {
	// padding: 0 $padding;
}

/////////////////////////////////////////////////
// FOOTNOTE /////////////////////////////////////
/////////////////////////////////////////////////
.c-footnote {
	@include colors( $footnote-background,
	$footnote-color);

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__content {
		padding: $padding 0;
		p {
			margin: 0;
		} // margin: 1em;
	}

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - DESKTOP ///////////////
/////////////////////////////////////////////////
@include media('<=desktop') {
	.c-footer__container {
		grid-template-columns: 35% 25% 40%;
	}

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=tablet') {
	.c-footer__container {
		grid-template-columns: 35% 22% 43%;
	}

}


/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////
@include media('<=phone') {
	.c-footer {
		position: relative;

	} // SWITCH FOOTER TO SINGLE COLUMN
	.c-footer__container {
		grid-template-columns: 100%;

	}

	.c-footer__content {}

	.c-footer__content--contact {
		width: 100%; // margin: 2em auto;
		padding: 3em 1.5em 0;

		h4 {
			font-size: 1.25em;
		}
		li {
			// font-size: 90%;
		}
	}

	.c-footnote__container {
		justify-content: space-around;

	}

	.c-footnote__content {
		padding: 1em 0;

		p {
			// font-size: 90%;
		}

	}

}
