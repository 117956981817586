.c-content {
padding: 1em;



    ul {
        margin-bottom: 1.5em;
    }

    li {
        background: url(../images/check.svg);
        background-repeat: no-repeat;
        background-size: 1em; // margin-left: 10em;
        background-position: 1.5em 0.25em;
        padding-left: 3.5em;
        margin-bottom: 0.5em;

    }

}

/////////////////////////////////////////////////
// RESPONSIVE ADJUSTMENTS - PHONE ///////////////
/////////////////////////////////////////////////

@include media('<=tablet') {
    
    .l-container--2-columns {
    //  grid-template-columns: auto;   
     

    }
    
    .c-content__image {
        width: 100%;
        height: auto;
    }
    .c-content {
        // padding: 0 1em;
        
        p {
            // font-size: 95%;
        }
        ul {
            // margin-bottom: 1em;
        }
        
        li {
            // font-size: 95%;
        }
    }
}
