// MIXINS
@mixin flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



@mixin colors($background, $color) {
    background-color: $background;
    color: $color;
    a {
        color: $color;
    }
}


@mixin border($color) {
    border: 2px dotted $color;
}

@mixin hidden {
    display: none;
}