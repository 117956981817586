.u-text-left { text-align: left !important; }
.u-text-center { text-align: center !important; }
.u-text-right { text-align: right !important; }

.u-padding {
  padding: 0 1em;
}

.u-hide-st-med {
  @media (man-width: 599px) {
    display: none !important;
  }
}

.u-hide-bp-med {
  @media (min-width: 600px) {
    display: none !important;
  }
}

.u-is-hidden-on-mobile {
  @include media('<=phone') {
    display: none;

}
}

.u-is-visible-on-mobile {
  @include media('>phone') {
    display: none;

}
}